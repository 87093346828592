import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import template from './bulk-import-study-roles.component.html';
import styles from './bulk-import-study-roles.component.scss';
import { StudyRolesService } from '../../../../shared/study-roles/study-roles.service';
import templateWithFreeTextRoles from '../../../../../assets/study-roles.xlsx';

interface RoleFilePreview {
    role: string
    error?: string
}

@Component({
    selector: 'bulk-import-study-roles',
    template,
    styles: [String(styles)]
})
export class BulkImportStudyRolesComponent implements OnInit {
    @Output() onFileImport: EventEmitter<RoleFilePreview[]> = new EventEmitter();
    @Input() numberOfExistingStudyRoles: number;

    public currentTeam: Team;
    public isProcessingFileUpload = false;
    public uploadedFile: File;
    public templateRoleUploaded = false;
    public errorCount = 0;
    public loadingSizes = ['40%', '45%', '30%'];
    public template: string;
    public previewData: RoleFilePreview[];

    constructor(
        private modalRef: BsModalRef,
        private Notifications: NotificationsService,
        private studyRolesService: StudyRolesService,
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();

        this.template = templateWithFreeTextRoles;
    }

    onFileAdded($event: FileList): void {
        this.isProcessingFileUpload = true;
        if ($event.length > 1) {
            this.Notifications.error('Please upload a single file.');
            this.isProcessingFileUpload = false;
            return;
        }

        const file = $event.item(0);
        const extension = file.name.split('.').pop();
        if (!(extension?.toLowerCase() === 'xlsx') && !(extension?.toLowerCase() === 'csv')) {
            this.Notifications.error('Only .xlsx or .csv files are allowed.');
            this.isProcessingFileUpload = false;
            return;
        }

        this.uploadedFile = file;
        this.templateRoleUploaded = true;

        this.studyRolesService.getStudyRolesFilePreview(this.currentTeam.teamId, file)
            .subscribe((data) => {
                this.previewData = [...data];


                this.isProcessingFileUpload = false;
                this.countErrors();
            }, ({ error }) => {
                this.Notifications.error(error.message || 'Failed to process the file. Please try again.');
                this.removeFile();
                this.isProcessingFileUpload = false;
            });
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    private countErrors(): void {
        this.errorCount = this.previewData.filter((data) => data.error).length;
    }

    removeFile(): void {
        this.uploadedFile = null;
        this.errorCount = 0;
        this.previewData = [];
        this.templateRoleUploaded = false;
    }

    onSubmit(): void {
        this.onFileImport.emit(this.previewData);
        this.hideModal();
    }
}
