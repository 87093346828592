import { Injectable } from '@angular/core';
import { Document, Folder } from '@app/shared/models';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResolvePermissionsService {

    resolvePermissions(
        options: { docs: Document[], folders: Folder[],
        resolve: (params: { objectId: string; objectType: string }) => Observable<{ [key: string]: boolean }> }
    ): Observable<{ [key: string]: boolean }> {

        const {
            docs,
            folders,
            resolve
        } = options;

        return forkJoin([
            this._calculateSmallestDocPrivileges(docs, resolve),
            this._calculateSmallestFolderPrivileges(folders, resolve)
        ]).pipe(
            map(([docPrivileges, folderPrivileges]) => {
                return this._mergeTwoHashesToSmallestPrivilegeHash(docPrivileges, folderPrivileges);
            })
        );
    }

    _mergeTwoHashesToSmallestPrivilegeHash(
        hash1: { [key: string]: boolean },
        hash2: { [key: string]: boolean }
    ): { [key: string]: boolean } {
        const result = {};

        if (!hash1 || !Object.keys(hash1).length) {
            return hash2;
        }

        if (!hash2 || !Object.keys(hash2).length) {
            return hash1;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const privilege of Object.keys(hash1)) {
            result[privilege] = hash1[privilege] || hash2[privilege];
        }

        return result;
    }

    _isDocumentDownloadable(doc: Document, docPerms: { [key: string]: boolean }): boolean {
        if (doc.hasPii) {
            return docPerms.downloadDocumentWithPii;
        }

        return docPerms.downloadDocumentWithPii || docPerms.downloadDocumentWithoutPii;
    }

    _isFolderDownloadable(folderPerms: { [key: string]: boolean }): boolean {
        const canDownload = folderPerms.downloadDocumentWithoutPii || folderPerms.downloadDocumentWithPii;
        const viewDownload = folderPerms.viewNonPiiInDocument || folderPerms.viewPiiInDocument;
        return canDownload && viewDownload;
    }

    _docPermsToPartialPrivilegeHash(doc: Document, docPerms: { [key: string]: boolean }): { [key: string]: boolean } {
        return {
            disableDownload: !this._isDocumentDownloadable(doc, docPerms),
            disableMove: !docPerms.moveDocument,
            disableDuplicate: !docPerms.duplicateDocument,
            disableAuditTrail: !docPerms.viewDocumentAuditTrail,
            disableEdit: !docPerms.renameDocument,
            disableDelete: !docPerms.destroyDocument,
            disableAssignTags: !docPerms.assignTags,
            disableAssignCategory: !(docPerms.updateDocumentCategory || docPerms.updateDocumentStatus),
            disableCreateShortcut: !docPerms.createDocumentShortcut,
            disableCreateTask: !docPerms.manageDocTasks,
            disableSetDueExpDate: !docPerms.manageDocumentDates,
            disableTogglePii: !docPerms.documentMarkUnmarkPhi,
            disableLock: !docPerms.lockDocument,
            disableUnlock: !docPerms.unlockDocument,
            disableMoveLocked: !docPerms.moveLockedDocument
        };
    }

    _folderPermsToPartialPrivilegeHash(folderPerms: { [key: string]: boolean }): { [key: string]: boolean } {
        return {
            disableDownload: !this._isFolderDownloadable(folderPerms),
            disableMove: !folderPerms.moveFolder,
            disableDuplicate: !folderPerms.duplicateFolder,
            disableAuditTrail: !folderPerms.viewFolderAuditTrail,
            disableEdit: !(folderPerms.renameFolder || folderPerms.labelAssign),
            disableRename: !folderPerms.renameFolder,
            disableDelete: !folderPerms.destroyFolder,
            disableFolderImportTemplates: !folderPerms.folderImportTemplates,
            disableManageArchive: !folderPerms.scheduleArchive
        };
    }

    _calculateSmallestDocPrivileges(
        docs: Document[],
        resolve: (params: { objectId: string; objectType: string }) => Observable<{ [key: string]: boolean }>
    ): Observable<{ [key: string]: boolean }> {
        if (!docs.length) {
            return of({});
        }

        const privilegeHashesObservables = docs.map((doc) => resolve({ objectId: doc.id.toString(), objectType: 'documents' })
            .pipe(
                map((docPerms) => this._docPermsToPartialPrivilegeHash(doc, docPerms))
            ));

        return forkJoin(privilegeHashesObservables).pipe(
            map((privilegeHashes) => {
                const smallestDocPrivileges = privilegeHashes.reduce(this._mergeTwoHashesToSmallestPrivilegeHash);
                const numberOfDocumentsWithPii = docs.filter((d) => d.hasPii).length;
                const onlyWithPiiSelected = numberOfDocumentsWithPii === docs.length;
                if (onlyWithPiiSelected) {
                    return smallestDocPrivileges;
                }
                const allowsDownloadForSome = privilegeHashes.some((hash) => !hash.disableDownload);
                smallestDocPrivileges.disableDownload = !allowsDownloadForSome;
                return smallestDocPrivileges;
            })
        );
    }

    _calculateSmallestFolderPrivileges(
        folders: Folder[],
        resolve: (params: { objectId: string; objectType: string }) => Observable<{ [key: string]: boolean }>
    ): Observable<{ [key: string]: boolean }> {
        if (!folders.length) {
            return of({});
        }

        const privilegeHashesObservables = folders.map((folder) => resolve({ objectId: folder.id, objectType: 'folders' })
            .pipe(
                map((folderPerms) => this._folderPermsToPartialPrivilegeHash(folderPerms))
            ));

        return forkJoin(privilegeHashesObservables)
            .pipe(
                map((privilegeHashes) => privilegeHashes.reduce(this._mergeTwoHashesToSmallestPrivilegeHash))
            );
    }
}
