export const FEATURE_FLAGS = {
    EBINDERS_DOCUMENTS_GENERAL_REPORT: 'eBinders-Documents-General-Report',
    EBINDERS_DOCUMENTS_OVERVIEW_CUSTOMER_MESSAGE: 'eBinders-Documents-Overview-Report-Customer-Message',
    EBINDERS_DOCUMENTS_OVERVIEW_MRM_COLUMN: 'eBinders-Documents-Overview-Report-MRM-Column',
    EBINDERS_SIP_REPORT: 'eBinders-SIP-report',
    EBINDERS_QC_REPORT: 'eBinders-QC-report',
    EBINDERS_APPROVAL_REPORT: 'eBinders-approval-report',
    EBINDERS_REPORT_TAGS_LABELS_SEARCH: 'eBinders-Reports-Tags-And-Labels-Search',
    UPID_RELATED_TO_MONITOR_GROUP: 'eBinder-UPID-related-to-Monitor-Group',
    STUDY_MANAGER_ENABLED: 'eBinders-study-manager',
    DOA_LOG_TEMPLATES: 'eBinders-doa-log-templates',
    DOA_LOG_PHASE_2: 'eBinders-doa-log-phase-2',
    NEW_ACCESS_DATES_REPORT: 'eBinders-Access-Dates-Report-Improvements',
    LOG_TEMPLATE_ENHANCEMENTS: 'ebinders-log-template-enhancements',
    ALL_PLACEHOLDERS_REPORT: 'eBinders-All-Placeholders-Report-Improvements',
    NDV_PAGE_MANIPULATION: 'ndv-page-edit-permission',
    LTA_RETENTION_PERIOD: 'eBinders-LTA-retention-period',
    STUDY_PROFILE_SIDEMENU_ENTRY: 'study-profile-sidemenu-entry',
    STUDY_ROLES: 'eBinders-study-roles',
    LOG_ENTRY_AUDIT_UPDATES: 'eBinders-log-entry-update-audit-details',
    EBINDERS_STUDY_SITE_TEAM: 'eBinders-study-site-team',
    BLINDED_UNBLINDED_ROLES: 'blinded-unblinded-roles',
    STUDY_PROFILE_ENHANCEMENT: 'study-profile-enhancement',
    DOA_LOG_TEMPLATE_ENHANCEMENT: 'doa-log-template-enhancement',
    EBINDERS_SEARCH_STUDY_PAGE: 'eBinders-search-study-page',
    EBINDERS_SEARCH_STUDIES_DOA_LOG_AUTOMATION: 'eBinders-search-studies-doa-log-automation',
    EBINDERS_RELEASE_VERSION: 'eBinders-release-version',
    EBINDERS_SEARCH_STUDY_ROLES: 'eBinders-search-study-roles',
    NDV_COPY_TEXT: 'ndv-copy-text',
    NDV_STRIKEOUT_TEXT: 'ndv-strikeout-text',
    EBINDERS_BULKUPLOAD_STUDYROLES: 'eBinders-bulkupload-studyroles',
    STUDY_PROFILE_VIEW_MODE: 'eBinders-studyprofile-viewmode',
    EBINDERS_BULKUPLOAD_DOALOGTEMPLATE_RESPONSIBILITIES: 'eBinders-bulkupload-DOAlogtemplate-responsibilities'
} as const;
